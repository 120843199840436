<template>
  <main>
    <div id="slide"></div>
    <div id="prof_wrapper" class="prof_wrapper"  v-bind:class="component">
      <div class="fullpage-wp" id="fullpage-wp">
        <div class="menu_resultats">
          <span>{{ $CD("intro") }}</span>
          <span
            v-bind:class="{ active : bindmenu == 'Traditionista' }"
            class="title-profil"
            @click="changeProfil('Traditionista')"
            >{{ $CD("NOM_DU_PROFIL_TRADITIONISTA") }}</span
          >
          <span
            v-bind:class="{ active: bindmenu == 'BbqMaster' }"
            class="title-profil"
            @click="changeProfil('Bbq-master')"
            >{{ $CD("NOM_DU_PROFIL_VIANDARD") }}</span
          >
          <span
            v-bind:class="{ active : bindmenu == 'Gastronomaniac' }"
            class="title-profil"
            @click="changeProfil('Gastronomaniac')"
            >{{ $CD("NOM_DU_PROFIL_GASTRONOMANIAC") }}</span
          >
          <span
            v-bind:class="{ active: bindmenu == 'StreetLover' }"
            class="title-profil"
            @click="changeProfil('StreetLover')"
            >{{ $CD("NOM_DU_PROFIL_STREET_LOVER") }}</span
          >
          <span
            v-bind:class="{ active: bindmenu == 'Aperitivore' }"
            class="title-profil"
            @click="changeProfil('Aperitivore')"
            >{{ $CD("NOM_DU_PROFIL_APERITIVORE") }}</span
          >
          <span
            v-bind:class="{ active: bindmenu == 'BecSucre' }"
            class="title-profil"
            @click="changeProfil('BecSucre')"
            >{{ $CD("NOM_DU_PROFIL_BEC_SUCRE") }}</span
          >
          <span
            v-bind:class="{ active: bindmenu == 'VeggieGourmand' }"
            class="title-profil"
            @click="changeProfil('VeggieGourmand')"
            >{{ $CD("NOM_DU_PROFIL_VEGGIE_GOURMAND") }}</span
          >
        </div>
        <div class="carousel-profils mobile">
          <span>Découvrez tous les profils foodista : </span>
          <carousel
            ref="carousel"
            v-bind:navigation-enabled="true"
            v-bind:paginationEnabled="false"
            @pageChange="onPageChange"
            navigationNextLabel="<img class='next-titre' src='/slides/next.png'>"
            navigationPrevLabel="<img class='prev-titre' src='/slides/prev.png'>"
            v-bind:per-page="1"
            v-bind:value="initialPage"
          >
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/traditionista.png" />
              </div>
            </slide>
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/gastronomaniac.png" />
              </div>
            </slide>
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/viandard.png" />
              </div>
            </slide>
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/street-lover.png" />
              </div>
            </slide>
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/aperitivore.png" />
              </div>
            </slide>
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/bec-sucre.png" />
              </div>
            </slide>
            <slide>
              <div v-if="fade" class="profil-accord">
                <img src="/slides/veggie-gourmand.png" />
              </div>
            </slide>
          </carousel>
        </div>
        <keep-alive>
          <transition name="fade">
            <component v-bind:is="component" :id="component" :withMentions="false" />
          </transition>
        </keep-alive>
         <component
          class="desktop"
          id="Bbq-master"
          v-bind:is="'BbqMaster'"
          v-if="component !== 'BbqMaster'"
          :withBG="true"
        />
        <component
          class="desktop"
          id="Traditionista"
          v-bind:is="'Traditionista'"
          v-if="component !== 'Traditionista'"
          :withBG="true"
        />
        <component
          class="desktop"
          id="Gastronomaniac"
          v-bind:is="'Gastronomaniac'"
          v-if="component !== 'Gastronomaniac'"
           :withBG="true"
        />
               <div id="test">
        <component
          class="desktop"
          id="StreetLover"
          v-bind:is="'StreetLover'"
          v-if="component !== 'StreetLover'"
             :withBG="true"
        /></div>
        <component
          class="desktop"
          id="Aperitivore"
          v-bind:is="'Aperitivore'"
          v-if="component !== 'Aperitivore'"
          :withBG="true"
        />
        <component
          class="desktop"
          id="BecSucre"
          v-bind:is="'BecSucre'"
          v-if="component !== 'BecSucre'"
          :withBG="true"
          :withMentions="component == 'VeggieGourmand'"
        />
        <component
          class="desktop"
          id="VeggieGourmand"
          v-bind:is="'VeggieGourmand'"
          v-if="component !== 'VeggieGourmand'"
          :withBG="true"
          :withMentions="true"
        />
      </div>
    </div>

  </main>
</template>
<script>
import Gastronomaniac from "../components/Gastronomaniac.vue";
import StreetLover from "../components/Street-lover.vue";
import Traditionista from "../components/Traditionista.vue";
import BecSucre from "../components/Bec-sucre.vue";
import BbqMaster from "../components/Bbq-master.vue";
import VeggieGourmand from "../components/Veggie-gourmand.vue";
import Aperitivore from "../components/Aperitivore.vue";

const mapPage = {
  traditionista: 0,
  gastronomaniac: 1,
  viandard: 2,
  "street-lover": 3,
  aperitivore: 4,
  "bec-sucre": 5,
  "veggie-gourmand": 6,
};
const mapComponent = {
  gastronomaniac: "Gastronomaniac",
  "bec-sucre": "BecSucre",
  "BbqMaster": "BbqMaster",
  traditionista: "Traditionista",
  aperitivore: "Aperitivore",
  "veggie-gourmand": "VeggieGourmand",
  "street-lover": "StreetLover",
};

export default {
  data() {
    return {
      bindmenu : mapComponent[this.$route.params._myascendant || "traditionista"],
      component:
        mapComponent[this.$route.params._myascendant || "traditionista"],
      fade: false,
      loading: true,
      initialPage: mapPage[this.$route.params._myascendant || "traditionista"],
      width: window.innerWidth,
    };
  },
	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},
  created() {
    window.addEventListener("resize", this.resizeHandler);

    if(this.$route.params._profil) {

      if( this.$route.params._profil != 'traditionista' && 
          this.$route.params._profil != 'gastronomaniac' &&  
          this.$route.params._profil != 'bbq-master' && 
          this.$route.params._profil != 'street-lover' && 
          this.$route.params._profil != 'aperitivore' && 
          this.$route.params._profil != 'bec-sucre' &&           
          this.$route.params._profil != 'veggie-gourmand') {
              this.$router.push("/404");
          }

        this.component = this.$route.params._profil   
        setTimeout(() => {
          if (window.innerWidth > 768) {
             var back = document.querySelector('.'+this.component);
             back.style.backgroundImage = "url('/profils/fond-"+this.component+".jpg')" ;   
          }
        }, 500)
    }
  },
  mounted() {
    setTimeout(() => {
    var doc = document.getElementById("fullpage-wp")
        doc.addEventListener("scroll", this.testScroll);
    }, 200)  
    setTimeout(() => {
      this.loading = false;
    }, 1500);
    console.log(this.initialPage, this.$refs.carousel);
    setTimeout(() => {
        this.$refs.carousel.goToPage(this.initialPage);
    }, 300)
    setTimeout(() => {
        this.fade = true;
    }, 700)
  },
  components: {
    Gastronomaniac,
    StreetLover,
    Traditionista,
    BecSucre,
    BbqMaster,
    VeggieGourmand,
    Aperitivore,
    carousel: VueCarousel.Carousel,
    slide: VueCarousel.Slide,
  },
  methods: {
    resizeHandler(e) {
      if(this.width <= 768) {
        return;
      }
      if(e.currentTarget.innerWidth <= 768) {
        window.location.reload();
      }
      this.width = e.currentTarget.innerWidth;
		},
    testScroll(e) {
      console.log(typeof e.target)
      var myTarget = document.getElementById("test")
      
  
         if(myTarget.offsetTop - window.scrollY <= 0){
            console.log(0)
         }
    },
    onPageChange(currentPage) {
      if (window.innerWidth < 768) {    
        console.log("onPageChange", currentPage);
        if (currentPage === 0) this.changeProfil("Traditionista");
        if (currentPage === 1) this.changeProfil("Gastronomaniac");
        if (currentPage === 2) this.changeProfil("BbqMaster");
        if (currentPage === 3) this.changeProfil("StreetLover");
        if (currentPage === 4) this.changeProfil("Aperitivore");
        if (currentPage === 5) this.changeProfil("BecSucre");
        if (currentPage === 6) this.changeProfil("VeggieGourmand");
      }
    },
    changeProfil(profil) {

      console.log(profil, window.innerWidth);
       this.bindmenu = profil;

        var back = document.querySelector('.'+this.component);
        back.style.backgroundImage = "url('/profils/fond-"+profil+".jpg')" ; 

      if (window.innerWidth < 768) {
        this.component = profil;

      } else if (!this.loading) {


        this.$scrollTo("#" + profil, { container: "#fullpage-wp" });
        console.log('profil --- ' , profil)
      }
    },
  },
};
</script>
<style lang="scss">
@import "../scss/_variables.scss";
.prof-annexe-left {
  img {
    @media screen and (min-width: 1600px) {
      display: none;
    }
  }
}
.next-titre {
  margin-left: -20px;
  margin-top: 30px;
  height: 30px;
}
.prev-titre {
  margin-right: -20px;
  margin-top: 30px;
  height: 30px;
}
.profil-accord {
  img {
    width: 300px;
  }
}
.carousel-profils {
  margin-top: 10px;
  text-align: center;
  width: 90%;
  height: 80px;
  margin: 0 auto;

  span {
    display: block;
    color: white;
    font-family: $font-extra;
    font-weight: bold;
    font-style: italic;
    font-size: 29px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.VueCarousel {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  margin: 0 auto;
}

.VueCarousel-navigation-button {
  top: 20% !important;
}

.menu_resultats {
  position:fixed;
  display: flex;
  align-items: center;

  height:50px;
  padding-bottom:10px;
  top: 84px;
  z-index: 5;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  span {
    display: inline-block;

    display: flex;
    align-items: center;
  }


  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-family: $font-extra;
  font-weight: bold;
  font-style: italic;
  font-size: 26px;

  .title-profil {
    font-size: 22px;
    text-transform: uppercase;
    padding-top: 8px;
    cursor: pointer;
  }

  .active {
   // color: black;
   // text-decoration: underline;
  }

  @media screen and (max-width: 1200px) {
    font-size: 24px;
    .title-profil {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 1200px) {
    font-size: 22px;
    .title-profil {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    //top: 110px;
    .title-profil {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
.gastronomaniac, .Gastronomaniac {
  background-image: url("/profils/fond-gastronomaniac.jpg");
  background-size: cover;
}

.Viandard {
  background-image: url("/profils/fond-bbq-master.jpg");
  background-size: cover;
}

.bbqmaster, .BbqMaster {
  background-image: url("/profils/fond-bbq-master.jpg");
  background-size: cover;
}

.traditionista, .Traditionista {
  background-image: url("/profils/fond-traditionista.jpg");
  background-size: cover;
}

.bec-sucre, .BecSucre {
  background-image: url("/profils/fond-bec-sucre.jpg");
  background-size: cover;
}

.street-lover, .StreetLover {
  background-image: url("/profils/fond-street-lover.jpg");
  background-size: cover;
}

.veggie-gourmand {
  background-image: url("/profils/fond-veggie-gourmand.jpg");
  background-size: cover;
}

.aperitivore, .Aperitivore {
  background-image: url("/profils/fond-aperitivore.jpg");
  background-size: cover;
}
</style>
