<template>
  <div class="prof withBG">
    <img class="prof-image-deco" :src="`/profils/${this.profil}.png`"> 
    <div class="prof-container">
      <div class="container-left">
        <img :src="`/profils/${this.profil}.png`">
      </div> 
      <div class="container-right">
        <div class="prof-content">
        <img class="img-titre" :src="`/profils/nv-${this.profil}.png`"> 
          <p class="prof-description white-bg-p-10" v-html="$CD('VOTRE_PROFIL_VIANDARD')" /> 
          <div class="prof-options">
            <div class="prof-options-left">
              <div class="prof-annexe">
                <div class="prof-annexe-left">
                  <img src="../assets/images/bottle-min.png">
                </div>
                <div class="prof-annexe-right">
                  <h2>Qu’est-ce que je bois ?</h2>
                  <p class="white-bg-p-10" v-html="$CD('CE_QUE_JE_BOIS_VIANDARD')" /> 
                </div>
              </div>
              <div class="prof-annexe">
                <div class="prof-annexe-left">
                  <img src="../assets/images/glasses-min.png">
                </div>
                <div class="prof-annexe-right">
                  <h2>Quel modéré je suis ?</h2>
                  <div class="white-bg-p-10">
                <h3 v-html="$CD('HAIKU_MODERATION_VIANDARD')" />
                  <p  v-html="$CD('TEXTE_QUEL_MODERE_ES_TU_VIANDARD')" /> 
                </div>
                </div>
              </div>
            </div>
            <div class="prof-options-right" style="position: relative;">
              <div class="prof-carousel">
                <div class="prof-annexe-left mobile">
                  <img style="width: 27%;margin-left: 2%;" src="../assets/images/ap2.png">
                </div>
                <div class="prof-annexe-left desktop">
                  <img style="position: absolute; left: -55px; width: 14.4%; top: 10px;" src="../assets/images/ap2.png">
                </div>
                <h2>Mes accords parfaits</h2>
                <carousel v-bind:navigation-enabled="true" v-bind:paginationEnabled="false"
                  navigationNextLabel="<img class='next-accords' src='/slides/next.png'>"
                  navigationPrevLabel="<img class='prev-accords' src='/slides/prev.png'>"
                  v-bind:perPageCustom="[[100, 1], [480, 2], [900, 1], [1050, 2], [1600, 1]]">
                  <slide>
                    <div class="prof-accord">
                      <div :style="`background-image: url('/recettes/viandard_1.png')`"></div>
                      <h4  v-html="$CD('TITRE_ACCORD_PARFAIT_1_VIANDARD')" />
                      <p  v-html="$CD('TEXTE_RECETTE_1_VIANDARD')" />
                      <p  v-html="$CD('LISTE_VIN_1_VIANDARD')" />
                    </div>
                  </slide>
                  <slide>
                    <div class="prof-accord">
                      <div :style="`background-image: url('/recettes/viandard_2.png')`"></div>
                      <h4  v-html="$CD('TITRE_ACCORD_PARFAIT_2_VIANDARD')" />
                      <p  v-html="$CD('TEXTE_RECETTE_2_VIANDARD')" />
                      <p  v-html="$CD('LISTE_VIN_2_VIANDARD')" />
                    </div>
                  </slide>
                  <slide>
                    <div class="prof-accord">
                      <div :style="`background-image: url('/recettes/viandard_3.png')`"></div>
                      <h4  v-html="$CD('TITRE_ACCORD_PARFAIT_3_VIANDARD')" />
                      <p  v-html="$CD('TEXTE_RECETTE_3_VIANDARD')" />
                      <p  v-html="$CD('LISTE_VIN_3_VIANDARD')" />
                    </div>
                  </slide>
                  <slide>
                    <div class="prof-accord">
                      <div :style="`background-image: url('/recettes/viandard_4.png')`"></div>
                      <h4  v-html="$CD('TITRE_ACCORD_PARFAIT_4_VIANDARD')" />
                      <p  v-html="$CD('TEXTE_RECETTE_4_VIANDARD')" />
                      <p  v-html="$CD('LISTE_VIN_4_VIANDARD')" />
                    </div>
                  </slide>                                                                 
                </carousel>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
    <br><br>
    <p style="display: block; width:350px !important; margin: auto; text-align:center;" class="white-bg-p-5 mobile">
      <router-link to="/mentions" style="color:black;text-decoration: underline !important;">Mentions légales</router-link>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    withBG: Boolean,
  },
  data() {
    return {
       profil : 'Bbq-master'
    }
  },
  components: {
    'carousel': VueCarousel.Carousel,
    'slide': VueCarousel.Slide
  }
}
</script>
<style lang="scss" scoped>

.withBG{
    background-image: url('/profils/fond-bbq-master.jpg');
    background-size: cover;
    @media screen and (max-width: 1290px) {
      background-image: url('/profils/fond-BbpMaster_v.jpg');
    }

    @media screen and (max-width: 768px) {
    background-image :unset;
  }    
}
</style>